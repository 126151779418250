import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Login from "../components/login"
import PasswordChange from "../components/passwordChange"


const App = () => (
  <Layout language="en">
    <Router>
      <PrivateRoute path="/enApp/profile" component={Profile} language="en" />
      <Login path="/enApp/log_in" language="en" />
      <PrivateRoute path="/enApp/change_password" component={PasswordChange} language="en" />
    </Router>
  </Layout>
)
export default App
